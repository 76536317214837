import React from 'react';
import * as styles from './Button.module.scss';

export interface ButtonProps {
  onClick: (e: any) => void;
  color?: string;
  children: React.ReactNode;
  inherite?: boolean;
  big?: boolean;
  slim?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  color,
  children,
  inherite,
  big,
  slim,
  style,
  className,
}) => {
  const theme =
    color === 'dark'
      ? styles.dark
      : color === 'green'
      ? styles.green
      : color === 'light'
      ? styles.light
      : color === 'red'
      ? styles.red
      : color === 'noHover'
      ? styles.noHover
      : styles.white;

  return (
    <div
      className={`${className} ${styles.buttonContainer} ${theme} ${inherite && styles.inherite} ${
        big && styles.big
      } ${slim && styles.slim}`}
      style={{ ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
