import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import './src/styles/globals.scss';
import '@fontsource/inter';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

import { Layout } from './src/layouts/Layout';
import { PageContextProvider } from './src/hooks/contexts/usePageContext';
import { WishlistContextProvider } from './src/hooks/contexts/useWishlistContext';
import { MenuContextProvider } from './src/hooks/contexts/useMenuContext';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
  <>
    {/* <Script async src="https://resa-tours.ingenie.fr/JsIframeResizer.js" type="text/javascript" />
    <Script
      async
      type="application/javascript"
      src="https://static.ingenie.fr/widgets/menu-engine/js/Client.js"
    />
    <Script
      async
      type="application/javascript"
      src="https://static.ingenie.fr/js/ScriptsLoader.js"
    />
    <Script
      async
      src="https://static.ingenie.fr/widgets/dispo_resa/Client.js"
      type="text/javascript"
    /> */}
    {/* <Script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js" />,
    <Script src="https://static.ingenie.fr/js/widgets/min/widget-resa.min.js" /> */}
    <PageContextProvider pageContext={props.pageContext}>
      <MenuContextProvider>
        <WishlistContextProvider>
          <Layout>{element}</Layout>
        </WishlistContextProvider>
      </MenuContextProvider>
    </PageContextProvider>
  </>
);
