import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'universal-cookie';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { Button } from '../button';
import * as styles from './CookieBanner.module.scss';
import { ToursBlockContent } from '../toursBlockContent/ToursBlockContent';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import useLocalStorage from '../../../../hooks/useLocalStorage';

export const CookieBanner = () => {
  // const location = useLocation();
  const { lang } = usePageContext();
  const { enable, disable, description } = useDataCookieBanner();
  const [consentBanner, setConsentBanner] = useLocalStorage('consentBanner', true);
  const location = useLocation();
  const cookies = new Cookies();

  // const activeCookie = () => {
  //   cookies.set('gatsby-gdpr-google-analytics', true);
  //   cookies.set('gatsby-gdpr-google-tagmanager', true);
  //   initializeAndTrack(location);
  //   setCookieBanner(false);
  // };

  // const desableCookie = () => {
  //   cookies.set('gatsby-gdpr-google-analytics', false);
  //   cookies.set('gatsby-gdpr-google-tagmanager', false);
  //   setCookieBanner(false);
  // };

  if (!consentBanner) return null;

  return (
    <div className={styles.wrapper}>
      <div>
        <ToursBlockContent blocks={description?.[lang] || ''} />
      </div>
      {/* <div>
        <div>Analyse du trafique : </div>
        <div></div>
      </div>
      <div>
        <div>g-tag : </div>
        <div></div>
      </div> */}
      <div>
        {enable?.[lang] && (
          <Button
            className={styles.button}
            color="dark"
            onClick={
              // activeCookie
              () => {
                setConsentBanner(false);
                cookies.set('gatsby-gdpr-google-tagmanager', true);
                initializeAndTrack(location);
              }
            }
          >
            {enable[lang]}
          </Button>
        )}
        {disable?.[lang] && (
          <Button
            className={styles.button}
            color="dark"
            onClick={
              () => {
                setConsentBanner(false);
                cookies.set('gatsby-gdpr-google-tagmanager', false);
              }
              // desableCookie
            }
          >
            {disable[lang]}
          </Button>
        )}
      </div>
    </div>
  );
};

const useDataCookieBanner = () => {
  const data = useStaticQuery(graphql`
    query cookieBanner {
      sanitySettingsLayout {
        cookieBanner {
          _rawButtonUnable
          _rawButtonDesable
          _rawDescription
        }
      }
    }
  `);

  return {
    enable: data?.sanitySettingsLayout?.cookieBanner._rawButtonUnable || '',
    disable: data?.sanitySettingsLayout?.cookieBanner._rawButtonDesable || '',
    description: data?.sanitySettingsLayout?.cookieBanner._rawDescription || '',
  };
};
