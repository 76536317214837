// extracted by mini-css-extract-plugin
export var blackColor = "#000";
export var breakpoint = "1024px";
export var button = "CookieBanner-module--button--12905";
export var buttonWrapper = "CookieBanner-module--buttonWrapper--eb794";
export var clickable = "CookieBanner-module--clickable--a128a";
export var displayDesktop = "CookieBanner-module--displayDesktop--4a868";
export var displayDesktopFlex = "CookieBanner-module--displayDesktopFlex--43bf5";
export var displayMobile = "CookieBanner-module--displayMobile--e6de9";
export var greenColor = "#1e8d0b";
export var lightBlackColor = "#444";
export var lightWightColor = "#fdf7f0";
export var pinkColor = "#f4e7e7";
export var redColor = "#f2604d";
export var whiteColor = "#fff";
export var wrapper = "CookieBanner-module--wrapper--bff7a";