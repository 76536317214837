import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import * as styles from './toursBlockContent.module.scss';

export interface ToursBlockContentProps {
  className?: string;
  blocks: any;
}

const Center = ({ children }: { children: React.ReactNode }) => (
  <div style={{ textAlign: 'center' }}>{children}</div>
);

export const ToursBlockContent: React.FC<ToursBlockContentProps> = ({ className, blocks }) => {
  const color = (props: any) => (
    <div
      style={{
        color: `rgba(${props?.mark?.rgb?.r},${props?.mark?.rgb?.g}, ${props?.mark?.rgb?.b}, ${props?.mark?.rgb?.a})`,
      }}
    >
      {props.children}
    </div>
  );

  const link = (props: any) => (
    <a href={props.mark.href} target={props.mark.target ?? '_blank'} className={styles.link}>
      {props.children}
    </a>
  );

  return (
    <BlockContent
      renderContainerOnSingleChild
      className={className ?? styles.defaultFont}
      blocks={blocks}
      imageOptions={{ fit: 'min' }}
      projectId={process.env.GATSBY_SANITY_PROJECT_ID || ''}
      dataset={process.env.GATSBY_SANITY_DATASET}
      serializers={{
        marks: { Center, color, link },
      }}
    />
  );
};
