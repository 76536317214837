// extracted by mini-css-extract-plugin
export var big = "Button-module--big--8f743";
export var blackColor = "#000";
export var breakpoint = "1024px";
export var buttonContainer = "Button-module--buttonContainer--c846a";
export var buttonWrapper = "Button-module--buttonWrapper--a4a8f";
export var clickable = "Button-module--clickable--0a753";
export var dark = "Button-module--dark--78b50";
export var displayDesktop = "Button-module--displayDesktop--23f5f";
export var displayDesktopFlex = "Button-module--displayDesktopFlex--69ecc";
export var displayMobile = "Button-module--displayMobile--557cb";
export var green = "Button-module--green--1a91f";
export var greenColor = "#1e8d0b";
export var inherite = "Button-module--inherite--917c6";
export var light = "Button-module--light--7398e";
export var lightBlackColor = "#444";
export var lightWightColor = "#fdf7f0";
export var noHover = "Button-module--noHover--cd895";
export var pinkColor = "#f4e7e7";
export var red = "Button-module--red--6257e";
export var redColor = "#f2604d";
export var slim = "Button-module--slim--873ef";
export var white = "Button-module--white--8e2a4";
export var whiteColor = "#fff";